<script setup>
import { useNuxtApp, ref, onMounted, nextTick, computed } from '#imports';

import isMobile from 'is-mobile';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

const { $tp } = useNuxtApp();

const props = defineProps({
  heading: {
    type: String,
    default: undefined,
  },
  maxHeight: {
    type: Number,
    default: 490,
  },
  text: {
    type: String,
    default: undefined,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return [''].includes(theme);
    },
  },
});

//<editor-fold desc="Custom scrollbar">
const simplebarWrapperRef = ref(null);
const simplebarInstance = ref(null);

onMounted(() => {
  nextTick(() => {
    initScrollbar();
  });
});

function initScrollbar() {
  if (isMobile()) return;
  if (simplebarInstance.value) return;

  simplebarInstance.value = new SimpleBar(simplebarWrapperRef.value, {});
}

//</editor-fold>

const maxHeightCss = computed(() => {
  return `${props.maxHeight / 16}em`;
});
</script>
<template>
  <div
    class="form-layout"
    :class="{
      [`form-layout--theme--${props.theme}`]: props.theme,
    }"
  >
    <div class="form-layout__heading">
      <div v-if="$slots.heading">
        <slot name="heading" />
      </div>

      <h3
        v-if="props.heading"
        class="form-layout__font form-layout__font--heading"
        v-html="$tp(props.heading)"
      />

      <div v-if="props.text" class="form-layout__text">
        <h4
          class="form-layout__font form-layout__font--text"
          v-html="$tp(props.text)"
        />
      </div>
    </div>

    <div
      ref="simplebarWrapperRef"
      class="form-layout__content theme-scrollbar theme-scrollbar--size--small"
    >
      <slot name="content" />
    </div>

    <div class="form-layout__footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.form-layout {
  $parent: &;

  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border-radius: em(48);

  @include media-breakpoint-down(sm) {
    border-radius: em(32);
  }

  &__font {
    &--heading {
      font-size: em(30);
      font-weight: 700;
      line-height: 1;
      letter-spacing: -0.03em;
    }

    &--text {
      font-size: em(13);
      line-height: 1.2;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: em(8);
    padding: em(36) em(96);
    border-bottom: 1px solid rgba($color-black, 0.1);
    border-radius: 0 0 em(48) em(48);

    @include media-breakpoint-down(sm) {
      padding: em(32) em(24);
      border-radius: 0 0 em(32) em(32);
    }
  }

  &__content {
    flex-grow: 1;
    max-height: v-bind(maxHeightCss);
    padding: em(32) em(96);
    overflow: hidden auto;

    @include media-breakpoint-down(sm) {
      max-height: none;
      padding: em(24);
      overflow: visible;
    }
  }

  &__footer {
    flex-shrink: 0;
    padding: em(32) em(96);
    border-top: 1px solid rgba($color-black, 0.1);
    border-radius: em(48) em(48) 0 0;
    box-shadow: 0 em(-16) em(36) 0 rgba($color-black, 0.05);

    @include media-breakpoint-down(sm) {
      padding: em(24);
      border-radius: em(32) em(32) 0 0;
    }
  }

  &__text {
    color: $color-grey-3;
  }
}
</style>
